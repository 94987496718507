export default [
  {
    caption: 'Set fees and limits',
    action: 'setFeesAndLimits',
  },
  {
    caption: 'Customize tags',
    action: 'customizeTags',
  },
  {
    caption: 'Set staking',
    action: 'setStaking',
  },
  {
    caption: 'Block',
    action: null,
    children: [
      {
        caption: 'General',
        action: 'block',
        payload: 'general',
      },
    ],
  },
];
