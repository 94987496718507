export default [
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'User',
    },
    cell: {
      type: 'slot',
      name: 'username',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Store',
    },
    cell: {
      type: 'slot',
      name: 'storename',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Product',
    },
    cell: {
      type: 'slot',
      name: 'product',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Status',
    },
    cell: {
      type: 'slot',
      name: 'status',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Category',
    },
    cell: {
      type: 'slot',
      name: 'category',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Price',
    },
    cell: {
      type: 'slot',
      name: 'price',
    },
  },
  {
    width: 15,
    header: {
      type: 'text',
      caption: 'Currency',
    },
    cell: {
      type: 'text',
      value: (e) => e.currency,
    },
  },
  {
    width: 25,
    header: {
      type: 'text',
      caption: 'Created',
    },
    cell: {
      type: 'slot',
      name: 'created',
    },
  },
];
