//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import constant from '~/const/index';
import navigationUser from '~/config/navigationUser';

export default {
  props: {
    username: { type: String, default: '' },
    id: { type: Number, default: 0 },
    onlineStatus: { type: Number, default: null },
  },

  computed: {
    color() {
      const { onlineStatus } = this;
      const { OFFLINE, ONLINE, AWAY } = constant.users.ONLINE_STATUS;
      if (onlineStatus === ONLINE) { return 'green'; }
      if (onlineStatus === AWAY) { return 'yellow'; }
      if (onlineStatus === OFFLINE) { return 'red'; }
      return '';
    },

    userRouteName() {
      return navigationUser[0]?.routeName || 'user-id';
    },
  },
};
