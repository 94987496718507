//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import Products from '~/views/MerchantsProducts/Products/Table.vue';

export default {
  components: {
    Products,
  },

  async mounted() {
    this.setGeneralLoader(true);
    await Promise.allSettled([
      this.load(),
    ]);
    this.setGeneralLoader(false);
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('merchantsProducts', {
      load: 'loadData',
    }),
  },
};
